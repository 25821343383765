<template>
  <HomePage/>
  <div class="bg-spincity-bg bg-cover bg-center fixed top-0 left-0  z-[-1] h-full w-full">

  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: "Comics";
  src: local("Comics"),
  url("assets/Comics Deluxe.ttf") format("truetype");
}


@import url('https://fonts.googleapis.com/css?family=Comic Neue:300,300i,400,400i,500,500i,700,700i,900,900i');
#app {
  font-family: Comics, "Comic Neue", "Comic Sans MS", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
