<template>
<div class="w-full flex flex-col my-[4vw] items-center justify-end h-full">


  <div style="
            color: white;
            margin: 0 0 50px 0;
            text-shadow: 0 0 5px #FF0000, 0 0 5px #FF0000, 0 0 10px #FF0000;
            text-align: center;
        "
       class="text-[4vw] md:text-[2vw] "
  >
    Start your crime family
  </div>

  <img v-if="!desktopHeader" class="overflow-visible w-[60vw] md:w-[38vw] h-auto " src="../assets/logo.png" alt=""/>

  <div class="flex flex-row mt-[5vw]">
    <div class="button red-grad z-10 cursor-default">
      Mint TBA
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HomeBody",

  data(){
    return {
      desktopHeader: window.innerWidth <= 767,
      mobileMenu:false
    }
  },
  created() {
    addEventListener("resize", () => {
      this.desktopHeader = innerWidth <= 767;
    });
  },
}
</script>

<style scoped>

</style>